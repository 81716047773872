import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


const Hifts = () => {
  return (
    <div className="d-grid gap-2">
        <Card className="text-center">
      <Card.Header>plaza</Card.Header>
      <Card.Body>
        <Card.Title>7am-3pm</Card.Title>
        <Card.Text>
          anyone can work
        </Card.Text>
        <Button variant="primary">Pick it up</Button>
      </Card.Body>
      <Card.Footer className="text-muted">needed asap</Card.Footer>
    </Card>      
    </div>
  )
}

export default Hifts;