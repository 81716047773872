import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import { useState, useEffect} from 'react'
import '../style.css'
import { Link } from 'react-router-dom';

const Locations = () => {
  const [locations,setLocations] = useState([])

  useEffect(() => {
    const FetchAllLocations = async ()=>{
      try{
        const res = await axios.get("http://localhost:8800/api/work", {
          withCredentials: true,
        })
        setLocations(res.data); 
      }catch(err){
        console.log(err)
      }
    }
    FetchAllLocations();
  }, []);
  
  return <div>
    <div className="work">
      {locations.map(location=>(
        <div className="locations" key={location.location_uid}>
         <h2>{location.Name}</h2>
          <img src={location.banner} alt='' />
          <Link to={`/work/${location.location_uid}`}>
          <button className="go" >See Open Shifts</button>
          </Link>
        </div>
      ))}
    </div>
  </div>
   
  
};

export default Locations