import axios from 'axios';
import React from 'react'
import { useState, useEffect} from 'react'
import './Add.css'
import { useNavigate} from 'react-router-dom';
import { AuthContext } from "../context/Authcontext";
import { useContext } from "react";

function Login() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [inputs,setInputs] = useState({
    email: "",
    password: "",
});

const [err,setErr] = useState(null)
  
const handleChange = (e) =>{
  setInputs(prev=>({...prev, [e.target.name]: e.target.value }))
}  


const handleLogin = async (e)=> {
  e.preventDefault();
  try{
    await login(inputs);
    navigate("/")
  }catch (err) {
    setErr(err.response.data);
  }
}

return (
<div className='form'>
    <h1>Login in</h1>
    <input required type="text" placeholder='Email' onChange={handleChange} name='email' />
    <input required type="password" placeholder='Password' onChange={handleChange} name='password' />
    <button className='formbutton' onClick={handleLogin}>Login</button>
    {err && <p>{err}</p>}
</div>
)
}

export default Login;