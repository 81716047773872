import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">Sfhs</Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/shift/add">Add shifts</Nav.Link>
            <Nav.Link href="/user/add">Add Users</Nav.Link>
            <Nav.Link href="/location/add">Add Location</Nav.Link>
            <Nav.Link href="/shift/delete">Delete shifts</Nav.Link>
            <Nav.Link href="/account">Account</Nav.Link>
            <Nav.Link href="https://sfhs.org">About</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;