import React from 'react'
import Shifts from './openbox'
import './work.css'
import axios from 'axios'
import { useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';

const Work = () => {
  const [location, setLocation] = useState({});
    
      const Location = useLocation()
  
      const locationId = Location.pathname.split("/")[2];
      
      useEffect(() => {
        const fetchData = async () =>{
          try {
            const res = await axios.get(`http://localhost:8800/api/work/${locationId}`, {
              withCredentials: true,
            });
            setLocation(res.data); 
          } catch(err){
            console.log(err)
          }
        }
        fetchData();
      }, [locationId]);
  
  return (
    <div>
      <div className="place">
      <div className="color"></div>
        <div className="banner">
          <img src={location.banner} alt="" />
          <h1>{location.Name}</h1>
        </div>
      </div>
      <Shifts />
    </div>
  )
}

export default Work
