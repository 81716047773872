import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './openbox.css'
import axios from 'axios'
import { useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';

const Shifts = () => {
  const [Shifts,setShifts] = useState([]);
  
  const Location = useLocation()

  const location_id = Location.pathname.split("/")[2]
  
  console.log(location_id)
  useEffect(() => {
    const FetchAllShifts = async ()=>{
      try{
        const res = await axios.get(`http://localhost:8800/api/shift?location_id=${location_id}`,{
          withCredentials: true,
        })
        setShifts(res.data); 
      }catch(err){
        console.log(err)
      }
    }
    FetchAllShifts();
  }, [location_id]);
  
  return <div>
    <div className="shift">
      {Shifts.map(shifts=>(
        <div className="shifts" key={shifts.id}>
        <h2>Date: {shifts.date}</h2>
         <h2>Hours: {shifts.hours}</h2>
         <h2>{shifts.desc}</h2>
        </div>
      ))}
    </div>
  </div>
   
  
};

export default Shifts;