import './App.css';
import {BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate, } from "react-router-dom";
import React from 'react';
import Home from './pages/Home'
import Login from './pages/Login'
import Work from './pages/work'
import Header from "./pages/Header"
import Shifts from './pages/Shifts'
import Add from './pages/Add'
import Addusers from './pages/Add-users'
import Account from './pages/Account'
import { useContext } from "react";
import Addplaces from './pages/Add-places'
import { useState, useEffect } from 'react'
import Deleteshifts from './pages/Delete-shifts'
import { AuthContext } from "./context/Authcontext";


function App() {

const { currentUser } = useContext(AuthContext);

const ProtectedRoute = ({ children }) => {
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return children;
};

  return (
    <div className='App'>
      <BrowserRouter>
      <Header />
   <Routes>
     <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/work/:id" element={<ProtectedRoute><Work /></ProtectedRoute>} />
        <Route path="/shift" element={<ProtectedRoute><Shifts /></ProtectedRoute>} />
        <Route path="/shift/add" element={<ProtectedRoute><Add /></ProtectedRoute>} />
        <Route path="/user/add" element={<ProtectedRoute><Addusers /></ProtectedRoute>} />
        <Route path="/account" element={<ProtectedRoute><Account /></ProtectedRoute>} />
      <Route path="/location/add" element={<ProtectedRoute><Addplaces /></ProtectedRoute>} />
      <Route path="/shift/delete" element={<ProtectedRoute><Deleteshifts /></ProtectedRoute>} />
   </Routes>
</BrowserRouter>
    </div>

  );
}
export default App;
