import React from 'react'
import Shifts from './openbox'
import axios from 'axios'
import { useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom';
import {useNavigate} from 'react-router-dom';



const Deleteshifts = () => {
    const [shifts,setShifts] = useState([]);
    const navigate = useNavigate();
    
    useEffect(() => {
      const fetchData = async ()=>{
        try{
          const res = await axios.get(`http://localhost:8800/api/shift`, {
            withCredentials: true,
          })
          setShifts(res.data); 
        }catch(err){
          console.log(err)
        }
      }
      fetchData();
    }, []);

    const handleDelete = (id, e)  =>{
        try{
           axios.delete(`http://localhost:8800/api/shift/${id}`, {
             withCredentials: true,
           })
           navigate("/shift/delete")
        }catch(err){
            console.log(err)
        }
   }
    
    
    return <div>
      <div className="shift">
        {shifts.map(shifts=>(
          <div className="shifts" key={shifts.shift_id}>
          <h2>Date: {shifts.date}</h2>
           <h2>Hours: {shifts.hours}</h2>
           <h2>{shifts.desc}</h2>
            <button className="pick" onClick={(e) => handleDelete(shifts.shift_id, e)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
}
     

export default Deleteshifts;