import axios from 'axios';
import React from 'react'
import { useState, useEffect} from 'react'
import './Add.css'
import { useNavigate} from 'react-router-dom';


const Addusers = () => {
    const [user,setUser] = useState({
        username: "",
        email: "",
        password: "",
        worklocation: "",
        admin: "",
    });

    const [err,setError] = useState(null)
   
    const navigate = useNavigate();


    const handleChange = (e) =>{
        setUser(prev=>({...prev, [e.target.name]: e.target.value }))
    }

    const handleClick = async e =>{
         e.preventDefault()
         try{
             await axios.post("http://localhost:8800/api/auth/register", user)
             navigate("/")
         }catch(err){
             console.log(err)
             setError(err.response.data); 
         }
    }
  return (
    <div className='form'>
        <h1>Add new Users</h1>
        <input type="text" placeholder='username' onChange={handleChange} name='username' />
        <input type="text" placeholder='email' onChange={handleChange} name='email' />
        <input type="text" placeholder='Password' onChange={handleChange} name='password' />
        <input type="text" placeholder='Work Location' onChange={handleChange} name='worklocation' />
        <input type="text" placeholder='admin' onChange={handleChange} name='admin' />
        <div className="buttonclick">       
        <div className="admin">
            <input type="radio" name='admin' value="true" id='true' />
            <label htmlFor="true">True</label>
        </div>
        <div className="admin">
            <input type="radio" name='admin' value="false" id='false' />
            <label htmlFor="false">False</label>
        </div>
        </div> 
        <button className='formbutton' onClick={handleClick}>Create</button>
        {err && <p>{err}</p>}
    </div>
  )
}

export default Addusers;