import React from 'react'
import Locations from "./Card"
import Hero from "./Hero"

const Home = () => {
  return (
    <div>
      <div className="hometext">
      <h1>Check open shifts</h1>
      </div>
      <Locations />
    </div>
  )
}

export default Home
