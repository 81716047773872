import axios from 'axios';
import React from 'react'
import { useState, useEffect} from 'react'
import './Add.css'
import { useNavigate} from 'react-router-dom';
import { AuthContext } from "../context/Authcontext";
import { useContext } from "react";



const Account = () => {

  const { currentUser } = useContext(AuthContext);
    const [user,setUser] = useState({
        username: "",
        email: "",
        password: "",
    });

    const [err,setError] = useState(null)

    const navigate = useNavigate();


    const handleChange = (e) =>{
        setUser(prev=>({...prev, [e.target.name]: e.target.value }))
    }

    const handleClick = async e =>{
         e.preventDefault()
         try{
             await axios.put(`http://localhost:8800/api/user/${currentUser.user_id}`, user)

         }catch(err){
             console.log(err)
             setError(err.response.data); 
         }
    }

  return (
    <div className='form'>
        <h1>Account info</h1>
        <h1>username: {currentUser.username}</h1>
        <h1>email: {currentUser.email}</h1>
        <input type="text" placeholder='Username' onChange={handleChange} name='username' />
        <input type="text" placeholder='Email' onChange={handleChange} name='email' />
        <input type="text" placeholder='Password' onChange={handleChange} name='password' />
        {err && <p>{err}</p>}
        <button className='formbutton' onClick={handleClick}>update</button>
    </div>
  )
}

export default Account;