import axios from 'axios';
import React from 'react'
import { useState, useEffect} from 'react'
import './Add.css'
import { useNavigate} from 'react-router-dom';


const Addplaces = () => {
    const [addplaces,setAddplaces] = useState({
        name: "",
        admin: "",
    });

    const [err,setError] = useState(null)

    const navigate = useNavigate();


    const handleChange = (e) =>{
        setAddplaces(prev=>({...prev, [e.target.name]: e.target.value }))
    }

    const handleClick = async e =>{
         e.preventDefault()
         try{
             await axios.post("http://localhost:8800/api/work/add", addplaces)
             navigate("/")
         }catch(err){
             console.log(err)
             setError(err.response.data); 
         }
    }
  return (
    <div className='form'>
        <h1>Add new Location's</h1>
        <input type="text" placeholder='Location Name' onChange={handleChange} name='name' />
        <input type="text" placeholder='Admin' onChange={handleChange} name='admin' />
        {err && <p>{err}</p>}
        <button className='formbutton' onClick={handleClick}>Create</button>
    </div>
  )
}

export default Addplaces;