import axios from 'axios';
import React from 'react'
import { useState, useEffect} from 'react'
import './Add.css'
import { useNavigate} from 'react-router-dom';


const Add = () => {
    const [shift,setShift] = useState({
        date: "",
        hours: "",
        description: "",
        location: "",
    });

    const [err,setError] = useState(null)
    const navigate = useNavigate();


    const handleChange = (e) =>{
        setShift(prev=>({...prev, [e.target.name]: e.target.value }))
    }

    const handleClick = async e =>{
         e.preventDefault()
         try{
             await axios.post("http://localhost:8800/api/shift/add", shift)
             navigate("/")
         }catch(err){
             console.log(err)
             setError(err.response.data); 
         }
    }
  return (
    <div className='form'>
        <h1>Add new shifts</h1>
        <input required type="text" placeholder='Date' onChange={handleChange} name='date' />
        <input required type="text" placeholder='Hours' onChange={handleChange} name='hours' />
        <input type="text" placeholder='Description' onChange={handleChange} name='description' />
        <input required type="text" placeholder='Location' onChange={handleChange} name='location' />
        {err && <p>shift create</p>}
        <button className='formbutton' onClick={handleClick}>Add</button>
    </div>
  )
}

export default Add